import React, { useEffect, useState } from 'react';
import { PopupWidget } from 'react-calendly'; // Import the correct component
import '../Mentorship.css';

const MentorshipfaqData = [
  {
    question: "Who is this mentorship for?",
    answer:
      "Entry-Level Learners: If you're new to coding or just getting started, this mentorship is perfect for building a strong foundation. \nIntermediate Learners: If you have some coding experience but need help expanding your skills, mastering new technologies, or tackling larger projects, this mentorship is ideal for you.",
  },
  {
    question: "What will I learn in these sessions?",
    answer:
      "Entry-Level: Fundamentals of programming (JavaScript, Python, HTML/CSS), building your first project or website, weekly code reviews, and debugging.\nIntermediate-Level: Advanced JavaScript, building full-stack apps, code optimization, best practices, real-world project development, and portfolio building.",
  },
  {
    question: "How do I book a session?",
    answer: "You can book a session directly on my website or contact me via email/social media to schedule a time that works for you.",
  },
  {
    question: "How much do the sessions cost?",
    answer:
      "Entry-Level Session: $50 per hour\nIntermediate Session: $75 per hour\n(Discounted packages available for multiple sessions)",
  },
  {
    question: "How long is each session?",
    answer: "Each session is 1 hour long, focused on tackling specific topics, reviewing your progress, and setting goals for the next week.",
  },
];

function MentorshipPitch() {
  const [openFAQ, setOpenFAQ] = useState(null);

  const toggleFAQ = (index) => {
    setOpenFAQ(openFAQ === index ? null : index);
  };

  // Trigger the Calendly widget on page load
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (window.Calendly) {
        window.Calendly.initPopupWidget({
          url: 'https://calendly.com/devpursuit-info',  // Replace with your own Calendly URL
        });
      }
    }, 1000); // Delay pop-up for 1 second (adjust as needed)

    return () => clearTimeout(timeout); // Cleanup timeout on unmount
  }, []);

  return (
    <div className="mentorship-pitch">
      <header className="header">
        <h1 className="heading">🚀 Ready to Level Up Your Coding Skills?</h1>
        <img src="/Images/Private.png" alt="Coding" className="hero-image" />
        <p className="intro-text">
        Are you ready to embark on a rewarding coding journey? Whether you're taking your first steps 
        into the world of programming or looking to enhance your existing skills, I offer personalized one-on-one 
        mentorship designed to guide you every step of the way. I provide tailored support for both beginners and intermediate coders,
        helping you define your goals, master new technologies, and build a portfolio you can be proud of.
        </p>
      </header>

      <section className="what-i-offer">
        <h2 className="section-heading"><strong>What I Offer:</strong></h2>
        <div className="mentorship-level">
          <h3><strong>1. Entry-Level Mentorship (For Beginners)</strong></h3>
          <p>Perfect for those with little to no prior coding experience, this program focuses on building a strong foundation and developing practical skills. We'll work together to:</p>
          <ul>
            <li><strong>Define Clear Goals:</strong>: We’ll collaborate to set achievable goals aligned with your interests, such as building your first website, 
            learning JavaScript fundamentals, or understanding core programming concepts.</li>
            <li><strong>Weekly Code Reviews</strong>: Receive personalized feedback on your code, troubleshoot challenges together, and learn best practices for clean 
            and efficient coding.</li>
            <li><strong>Achieve Tangible Milestones</strong>: Celebrate your progress with clear milestones, such as completing your first project, mastering a new concept, or confidently using a 
            specific tool.</li>
            <li><strong>Master Essential Skills & Build a Portfolio</strong>: Gain hands-on experience by building real-world applications and creating a portfolio to showcase your newfound 
            abilities.</li>
          </ul>
        </div>

        <div className="mentorship-level">
          <h3><strong>2. Intermediate Mentorship (For Learners with Some Experience)</strong></h3>
          <p>Designed for coders who have some existing knowledge and are ready to take their skills to the next level. We'll focus on:</p>
          <ul>
            <li><strong>Targeted Goal Setting</strong>: We’ll identify your specific coding goals, such as mastering frameworks like React, building a backend with Node.js,
             or working with databases, and tailor our sessions accordingly.</li>
            <li><strong>In-Depth Code Reviews</strong>: Dive deeper into your code to optimize performance, improve architecture, and ensure adherence to industry best practices.</li>
            <li><strong>Achieve Significant Milestones</strong>: Track your progress with ambitious milestones, such as developing a full-stack application, creating a RESTful API, 
            or contributing to open-source projects.</li>
            <li><strong>Master Advanced Skills & Expand Your Portfolio</strong>: Master in-demand frameworks, libraries, and advanced coding concepts while working on challenging 
            projects that will significantly enhance your portfolio and career prospects.</li>
          </ul>
        </div>

        <h2 className="section-heading"><strong>Session Details</strong></h2>
        <p><strong>Duration:</strong> 1-hour sessions</p>
        <p><strong>Format:</strong> One-on-one video call via Zoom (or your preferred platform)</p>
        <p><strong>Availability:</strong> Flexible scheduling based on your time zone</p>
        <p><strong>Pricing:</strong> Entry-Level Session: $50/hour, Intermediate Session: $75/hour</p>
      </section>

      <section className="Mentorshipfaq">
        <h2 className="Mentorship-section-heading">Frequently Asked Questions</h2>
        {MentorshipfaqData.map((faq, index) => (
          <div key={index} className="Mentorshipfaq-item">
            <div className="Mentorshipfaq-question" onClick={() => toggleFAQ(index)}>
              <h3>{faq.question}</h3>
            </div>
            {openFAQ === index && (
              <div className="faq-answer">
                <p>{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </section>

      {/* Calendly Popup Widget */}
      <PopupWidget
        url="https://calendly.com/devpursuit-info"  // Replace with your own Calendly URL
        rootElement={document.getElementById("root")} // Ensure it's attached to the root element
      />
    </div>
  );
}

export default MentorshipPitch;
