import React from 'react';
import '../EbookDownloadPage.css'; 

function EbookDownloadPage() {
  return (
    <div className="ebook-gallery">
      <header className="ebook-header">
        <h1>Explore Our Free Ebook Collection</h1>
        <p className="subheading">Download helpful ebooks to boost your coding skills and knowledge.</p>
      </header>

      <section className="ebook-grid">
        {/* Ebook 1 */}
        <div className="ebook-item">
          <img className="ebook-img" src="/Images/DP_Ebook.png" alt="Code That Shines Ebook" />
          <div className="ebook-info">
            <h3>Code That Shines</h3>
            <p>10 Essential Tips for Writing Clean and Maintainable Code</p>
            <a href="/Ebooks/Code_That_Shines_ebook.pdf" download="Code_That_Shines_ebook.pdf">
              <button className="download-button">Download the Ebook</button>
            </a>
          </div>
        </div>

        {/* You can add more ebooks in similar format */}
        <div className="ebook-item">
          <img className="ebook-img" src="/Images/DP_Ebook2.png" alt="10 easy steps to start your coding adventure" />
          <div className="ebook-info">
            <h3>Start Your Journey</h3>
            <p>Key Steps to Kickstart Your Coding Journey</p>
            <a href="/Ebooks/Start_Your_Journey.pdf" download="Mastering_JavaScript_ebook.pdf">
              <button className="download-button">Download the Ebook</button>
            </a>
          </div>
        </div>

        {/* Add more ebook items as needed */}
        <div className="ebook-item">
          <img className="ebook-img" src="/Images/AutoBio.png" alt="Digital Dreamer: Crafting My Future in Tech" />
          <div className="ebook-info">
            <h3>Digital Dreamer: Crafting My Future in Tech</h3>
            <p>Embark on a transformative journey through the tech industry. Discover the challenges, 
              triumphs, and insights that shaped my path from novice to professional.</p>
            <a href="/Ebooks/Digital_Dreamer.pdf" download="Digital_Dreamer.pdf">
              <button className="download-button">Download the Ebook</button>
            </a>
          </div>
        </div>

        <div className="ebook-item">
          <img className="ebook-img" src="/Images/Agility.webp" alt="Agility For Devs" />
          <div className="ebook-info">
            <h3>Agility For Developers</h3>
            <p>The Developer’s New Role in an Agile World</p>
            <a href="/Ebooks/Agility_for_Developers.pdf" download="Agility_for_Developers.pdf">
              <button className="download-button">Download the Ebook</button>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default EbookDownloadPage;
