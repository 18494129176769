import React from "react";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const MyNavbar = () => {
  return (
    <Navbar collapseOnSelect expand="lg" variant="dark" className="custom-navbar">
      <Container>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/" className="custom-link">Home</Nav.Link>
            <Nav.Link as={Link} to="/about" className="custom-link">About</Nav.Link>
            <Nav.Link as={Link} to="/ebooks" className="custom-link">Ebooks</Nav.Link>
            <NavDropdown title="Services" id="collasible-nav-dropdown" className="custom-link">
              <NavDropdown.Item as={Link} to="/mentorship" className="custom-link">Mentorship</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MyNavbar;
